import { IHeroEntry } from 'models/landingpage'
import Link from 'next-translate-routes/link'
import React from 'react'
import ReactPlayer from 'react-player'

import { ProductSlider } from '../slider/product-slider'
import { BaseText } from '../text/base-text'
import { Border } from './border'

export interface HeroEntryProps {
  data: IHeroEntry
}

export function TrustElement(props: {
  color: string
  text: string
  position: string
}) {
  const positionVariants = {
    'oben-links': 'left-[calc(25%-8rem)] top-[calc(25%-14rem)]',
    'oben-rechts': 'left-[calc(75%-8rem)] top-[calc(25%-14rem)]',
    'unten-links': 'left-[calc(25%-8rem)] top-[calc(75%-14rem)]',
    'unten-rechts': 'left-[calc(75%-8rem)] top-[calc(75%-14rem)]',
  }
  return (
    <div
      className={`absolute mx-auto ${
        positionVariants[props.position]
      } invisible sm:visible z-[99] text-center `}
    >
      <div
        className={`w-48 h-48 rounded-full bg-${props.color} flex items-center justify-center font-swDisp text-2xl italic font-bold`}
      >
        {props.text}
      </div>
    </div>
  )
}

export const HeroEntry = ({ data }: HeroEntryProps) => {
  const crop =
    data.HeroImages?.length > 0 ? data.HeroImages[0]?.crop : undefined

  return (
    <div className={`relative`}>
      {data && (
        <div
          className={`mx-auto ${data.Fullscreen ? '' : 'container'} bg-${
            data?.Farbe
          } `}
        >
          <div className={`mx-auto container`}>
            <div
              className={`sm:flex sm:flex-row flex-wrap sm:flex-nowrap ${
                data.Anordnung === '0' || data.Anordnung === null
                  ? ''
                  : 'block sm:flex-row-reverse'
              }`}
            >
              <div className="w-full sm:w-8/12">
                <div className="relative h-full w-full justify-center center items-center mx-auto align-center">
                  {(data?.HeroVideo?.data?.id ||
                    data?.HeroVideo?.data?.filename) && (
                    <ReactPlayer
                      // url={`https://stayspicedraw.azureedge.net/Videos/${data.HeroVideo.data.filename}`}
                      url={`${
                        data?.HeroVideo?.data?.filename
                          ? `https://stayspicedraw.azureedge.net/Videos/${data.HeroVideo.data.filename}`
                          : `https://www.youtube.com/watch?v=${data?.HeroVideo?.data?.id}`
                      }`}
                      className={`fullscreen-video center items-center align-center mx-auto`}
                      controls={false}
                      playsinline={true}
                      playing
                      loop
                      muted
                      height="100%"
                    />
                  )}
                  {!data?.HeroVideo?.data &&
                    data.HeroImages &&
                    data.HeroImages.length > 0 && (
                      <div
                        className="sm:min-h-auto m-auto h-80 w-full sm:h-full"
                        style={{
                          backgroundImage: `url(https://spiceworld.azureedge.net/?image=${data.HeroImages[0]?.image.fullpath}&cw=${crop?.cropWidth}&cl=${crop?.cropLeft}&ct=${crop?.cropTop}&ch=${crop?.cropHeight}&bg=1)`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                    )}
                </div>
              </div>
              {data.TrustText && (
                <TrustElement
                  key={data.TrustText}
                  text={data.TrustText}
                  color={data.TrustColor}
                  position={data.Position}
                />
              )}
              <div className="z-0 h-full w-full p-8 sm:m-8 sm:w-4/12 sm:p-0">
                <BaseText
                  headline={data.Name}
                  subHeadline={data.Subline}
                  text={data.IntroText}
                  tagType={data.tagType}
                />

                {data.Products && data.Products.length > 0 && (
                  <>
                    <Border />
                    <ProductSlider
                      key={`hero_slider`}
                      itemcount={1}
                      bgColor="transparent"
                      products={data.Products as any[]}
                      title="Most trending products"
                      sizeVW="10"
                    />
                  </>
                )}
                {!data.Products && data.CTAText && data.Verlinkung && (
                  <Link
                    href={`${
                      data.Verlinkung?.classname.includes('ategor')
                        ? '/kategorie/'
                        : '/'
                    }${data.Verlinkung?.Slug ?? ''}`}
                  >
                    <a className="my-4 inline-block w-4/5 border-2 border-black bg-transparent py-2 text-center font-swDisp text-xl font-bold text-black hover:bg-9 dark:text-white">
                      {data.CTAText}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default React.memo(HeroEntry)
