import { HeroImage, IProductTeaser, ITextImage } from 'models/landingpage'
import Link from 'next-translate-routes/link'
import React from 'react'

import { TrustElement } from './hero-entry'

export interface ProductTeaserProps {
  data: IProductTeaser
}

export const TextBlockWithCta = ({
  CTAText,
  Content,
  Headline,
  Landingpage,
  classname,
  Subheadline,
}: ITextImage) => {
  return (
    <div className="m-auto w-full pb-4 sm:w-1/2 sm:pb-0">
      <h3 className="font-swDisp text-2xl font-bold italic">{Headline}</h3>
      <h4 className="font-swDisp text-xl font-bold">{Subheadline}</h4>
      <div
        className="relative w-full max-w-full font-swText text-base"
        dangerouslySetInnerHTML={{ __html: Content }}
      />
      {CTAText && (
        <Link
          href={`${
            Landingpage?.classname?.includes('ategor')
              ? '/kategorie/'
              : Landingpage?.classname?.includes('ecipe')
              ? '/rezept/'
              : '/'
          }${Landingpage?.Slug ?? ''}`}
        >
          <a className="my-4 inline-block w-full border border-black bg-aktion py-2 text-center font-swDisp text-xl font-bold text-black hover:bg-9 dark:text-white  sm:w-4/5">
            {CTAText}
          </a>
        </Link>
      )}
    </div>
  )
}

const ImageItem = ({ image }: HeroImage) => {
  return (
    <div className="w-full py-4 sm:w-1/2 sm:py-0">
      <div className="relative w-full ">
        <img
          loading="lazy"
          src={`${process.env.NEXT_PUBLIC_BLOB_URL}${image.fullpath}&bg=1`}
          className="w-full"
        />
      </div>
    </div>
  )
}

export const TextImage = (props: ITextImage) => {
  return (
    <div className={`relative`}>
      {props.TrustText && (
        <TrustElement
          color={props.TrustColor}
          text={props.TrustText}
          position={props.Position}
        />
      )}
      <div
        className={`w-full py-8 ${
          props.seventyPercentBackground
            ? `bg-${props.Farbe}-70`
            : `bg-${props.Farbe}`
        }`}
      >
        <div className="container mx-auto w-full">
          <div
            className={`block px-4 sm:p-0 sm:flex content-center ${
              props.Image ? 'gap-16' : ''
            } justify-center`}
          >
            {props.ImageAlignment !== true ? (
              <>
                <TextBlockWithCta {...props} />
                {props.Image && <ImageItem {...props.Image} />}
              </>
            ) : (
              <>
                {props.Image && <ImageItem {...props.Image} />}
                <TextBlockWithCta {...props} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(TextImage)
