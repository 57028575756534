import React from 'react'

export interface BorderProps {
  color?: string

  width?: number
}

export const Border = ({ color, width }: BorderProps) => {
  return (
    <div className="py-8">
      <div className={`w-full border-t border-${color} border-t-${width}`} />
    </div>
  )
}

Border.defaultProps = {
  color: 'black',
  width: 1,
}
